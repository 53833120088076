import * as service from './service';
import modal from 'views/central/modals/virtual_appliance_licence_expired.html';
import virtualApplianceLicenceExpiredPartial from 'views/central/partials/virtual_appliance_licence_expired_partial.html';

let $modal;

const getModal = () => {
	return $modal;
};

const setModal = () => {
	$modal = $('#virtual-appliance-licence-expired');
};
const refreshFromCentral = (event) => {
	event.preventDefault();
	let refreshButton = event.currentTarget;
	refreshButton.setState('loading');
	service.getDetails()
		.then((central) => {
			let olderMaintenanceExpiresOn = moment(central.maintenance.expiresOn);
			service.refresh()
				.then((central) => {
					let hasMaintenanceChanged = (olderMaintenanceExpiresOn.isSame(moment(central.maintenance.expiresOn)) === false);
					if (!hasMaintenanceChanged) {
						app.notifier.add({ text: app.t('system:phrases.maintenancePeriodNotExtended'), type: 'warning' });
					} else {
						if (central.maintenance.isExpired) {
							app.notifier.add({ text: app.t('system:phrases.maintenanceWasValidFromDate1ToDate2', { date1: moment(central.maintenance.startDate).format('YYYY/MM/DD'), date2: moment(central.maintenance.expiresOn).format('YYYY/MM/DD') }), type: 'info' });
						} else {
							app.notifier.add({ text: app.t('system:phrases.isValidUntilDate', { date: moment(central.maintenance.expiresOn).format('YYYY/MM/DD') }), type: 'success' });
						}
						$modal.modal('hide');
					}
				})
				.catch((error) => {
					app.notifier.add({ text: (error?.response?.message ?? app.t('phrases.couldNotRetrieveUpdatesFromCentral')), type: 'error' });
				})
				.then(() => {
					refreshButton.reset();
				});
		});
};

const render = (state) => {
	if (_.isNull(state.central.maintenance)) {
		return;
	}

	if (!product.isVirtualized) {
		return;
	}

	if (product.isInAssemblyMode) {
		return;
	}

	if (!state.central.maintenance.isExpired) {
		return;
	}

	if (app.user.username === 'support') {
		return;
	}

	getModal().find('.modal-body').html(app.render(virtualApplianceLicenceExpiredPartial, { maintenance: state.central.maintenance }));
	getModal().modal('show');
};

const init = () => {
	$app.append(app.render(modal));

	setModal();
	getModal()
		.on('click', '.refresh', refreshFromCentral);

	service.subscribe([render]);
};

export {
	init
};
