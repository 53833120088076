import Store, { INIT_STATE } from './store';

const GET_AUTHORIZATION = 'GET_AUTHORIZATION';
const GET_SETTINGS = 'GET_SETTINGS';
const AUTHENTICATE = 'AUTHENTICATE';
const TOGGLE_MFA = 'TOGGLE_MFA';
const TOGGLE_CONTINUOUS_AUTH = 'TOGGLE_CONTINUOUS_AUTH';

class Auth extends Store {
	constructor() {
		const initialState = {
			auth: {
				isAuthenticated: false
			},
			settings: null
		};
		super();
		this.setState(initialState, INIT_STATE);
		
		this.subscribeToProperties(['auth'], (store) => {
			let { state } = store;
			if ((state?.auth ?? false) === false) {
				return;
			}
			
			if (state.auth.isAuthenticated) {
				this.getSettings();
			}
		});
	}

	fetchStatus() {
		let auth;
		return this.get('/api/auth/authorization')
			.then(() => {
				auth = { isAuthenticated: true };
			})
			.catch((error) => {
				auth = { isAuthenticated: false };
				localStorage.removeItem('user');
			})
			.then(() => {
				this.setState({ auth: auth }, GET_AUTHORIZATION);
				return auth;
			});
	}

	authenticate(credentials) {
		return this.post(
			'/api/auth/session',
			credentials
		)
			.then((response) => {
				if (response.status === 200) {
					let user = response.data;
					user.isSuper = _.includes(['administrator'], user.role.toLowerCase());
					localStorage.setItem('user', JSON.stringify(user));
					let auth = this.getStateProperty('auth');
					_.assign(auth, { isAuthenticated: true });
					this.setState({ auth: auth }, AUTHENTICATE);
					return;
				}
				if (response.status === 203) {
					return {
						authenticatorLink: response.data.authenticatorLink,
						secret: response.data.secret
					};
				}
				if (response.status === 204) {
					return { authenticatorLink: null };
				}
			});
	}

	verifyCode(data) {
		return this.post(
			'/api/auth/session/otp',
			data
		)
			.then((response) => {
				let user = response.data;
				user.isSuper = _.includes(['administrator'], user.role.toLowerCase());
				localStorage.setItem('user', JSON.stringify(user));
				let auth = this.getStateProperty('auth');
				_.assign(auth, { isAuthenticated: true });
				this.setState({ auth: auth }, AUTHENTICATE);
				return response.data.recoveryCodes;
			});
	}

	invalidate() {
		return this.delete('/api/auth/session')
			.then(() => {
				localStorage.removeItem('user');
				Store.clearState();
			});
	}

	// Settings

	fetchSettings() {
		let settings;
		return this.get('/api/auth/settings')
			.then((response) => {
				settings = response.data;
				this.setState({ settings: settings }, GET_SETTINGS);
				return settings;
			})
			.catch((error) => {
				settings = false;
				this.setState({ settings: settings }, GET_SETTINGS);
				return settings;
			});
	}


	getSettings() {
		let settings = this.getStateProperty('settings');

		if (!_.isNull(settings)) {
			return this.createPromise(settings);
		}

		return this.fetchSettings();
	}

	toggleMfa(enabled, auth) {
		return this.patch(
			'/api/auth/settings',
			{ isOtpEnabled: enabled },
			{
				headers: { 'continuous-auth': auth }
			}
		)
			.then(() => {
				let settings = this.getStateProperty('settings');
				settings.isOtpEnabled = enabled;
				this.setState({ settings: settings }, TOGGLE_MFA);
				return settings;
			});
	}

	toggleContinuousAuth(enabled, auth) {
		return this.patch(
			'/api/auth/settings',
			{ isContinuousAuthEnabled: enabled },
			{
				headers: { 'continuous-auth': auth }
			}
		)
			.then(() => {
				let settings = this.getStateProperty('settings');
				settings.isContinuousAuthEnabled = enabled;
				this.setState({ settings: settings }, TOGGLE_CONTINUOUS_AUTH);
				return settings;
			});
	}

}

export default new Auth();
