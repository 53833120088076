import X from '../x';
import template from './button.html';

customElements.define('x-button', class Button extends X {
	#button;
	#initialDisabled;
	#initialSlot;
	#template = template;
	
	static get observedAttributes() {
		return ['variant', 'outline', 'size', 'stretch', 'disabled', 'p', 'px', 'py', 'ps', 'pe', 'pt', 'pb', 'm', 'mx', 'my', 'ms', 'me', 'mt', 'mb'];
	}

	constructor() {
		super();

		this.#render();
		this.#attachEventListeners();
	}

	formResetCallback() {
		this.reset();
		if (this.#initialDisabled) {
			this.toggleAttribute('disabled');
		}
	}
	
	attributeChangedCallback(name, oldValue, newValue) {
		if (name === 'variant') {
			this.#setVariant(newValue, this.hasAttribute('outline'));
		}
		if (name === 'outline') {
			this.#setVariant(this.getAttribute('variant'), (newValue !== null));
		}
		if (name === 'size') {
			this.#button.classList.remove(`btn-${oldValue}`);
			this.#button.classList.add(`btn-${newValue}`);
		}
		if (name === 'stretch') {
			this.#button.classList.toggle('h-100', (newValue !== null));
		}
		if (name === 'disabled') {
			this.#button.disabled = (newValue !== null);
		}
		if (['p', 'px', 'py', 'ps', 'pe', 'pt', 'pb', 'm', 'mx', 'my', 'ms', 'me', 'mt', 'mb'].includes(name)) {
			this.#button.classList.add(`${name}-${newValue}`);
		}
	}

	get type() {
		return this.hasAttribute('type') ? this.getAttribute('type') : 'button';
	}

	get nodeName() {
		return 'BUTTON';
	}
	
	get variant() {
		return this.getAttribute('variant');
	}
	
	set variant(value) {
		this.setAttribute('variant', value);
	}
	
	get outline() {
		return this.hasAttribute('outline');
	}
	
	set outline(value) {
		this.removeAttribute('outline');
		if (String(value) === 'true') {
			this.toggleAttribute('outline');
		}
	}

	get disabled() {
		return this.hasAttribute('disabled');
	}

	set disabled(value) {
		this.removeAttribute('disabled');
		if (String(value) === 'true') {
			this.toggleAttribute('disabled');
		}
	}
	
	#setVariant(variant, outline) {
		let classList = this.#button.classList;
		let classesToRemove = Array.from(classList).filter((className) => { return (className !== 'btn-sm' && className !== 'btn-lg') && (className.startsWith('btn-') || className.startsWith('link-')); });
		if (classesToRemove.length > 0) {
			classList.remove(...classesToRemove);
		}
		variant = variant || 'primary';
		if (variant.includes('link-')) {
			classList.add(variant);
			variant = 'link';
		}
		if (outline) {
			variant = `outline-${variant}`;
		}
		if (variant !== 'none') {
			classList.add(`btn-${variant}`);
		}
	}

	#render() {
		let template = document.createElement('template');
		template.innerHTML = this.#template;
		this.shadowRoot.appendChild(template.content.cloneNode(true));

		this.#button = this.shadowRoot.querySelector('button');
		if (!this.hasAttribute('variant')) {
			this.#setVariant();
		}
		this.#initialDisabled = this.hasAttribute('disabled');
		this.#initialSlot = this.innerHTML;
	}

	#ripple(event) {
		let ripple = this.#button.querySelector('.ripple');
		if (!ripple) {
			ripple = document.createElement('span');
			ripple.classList.add('ripple');
			this.#button.appendChild(ripple);
		}

		ripple.classList.remove('animate');

		let diameter = Math.max(this.#button.offsetWidth, this.#button.offsetHeight);
		if (!ripple.style.height && !ripple.style.width) {
			ripple.style.height = `${diameter}px`;
			ripple.style.width = `${diameter}px`;
		}

		let rect = this.#button.getBoundingClientRect();
		let offset = { 
			top: rect.top + window.scrollY, 
			left: rect.left + window.scrollX, 
		};
		let top = event.pageY - offset.top - diameter / 2;
		let left = event.pageX - offset.left - diameter / 2;

		ripple.style.top = `${top}px`;
		ripple.style.left = `${left}px`;
		ripple.classList.add('animate');
		
		setTimeout(() => {
			ripple.classList.remove('animate');
		}, 500);
	}

	#attachEventListeners() {
		this.#button.addEventListener('keypress', (event) => {
			this.#ripple(event);
			if (event.code.toLowerCase() === 'space') {
				submitForm.call(this, event);
			}
		});

		this.#button.addEventListener('click', (event) => {
			this.#ripple(event);
			submitForm.call(this, event);
		});

		function submitForm(event) {
			if (this.type !== 'submit') {
				return;
			}

			let form = this.closest('form');
			if (form) {
				form.dispatchEvent(new event.constructor('submit', event));
			}
		}
	}

	setState(state) {
		this.removeAttribute('disabled');
		this.toggleAttribute('disabled');
		this.innerHTML = this.getAttribute(`${state}-text`) || app.t('labels.loading');

		if (state === 'loading') {
			let loadingProgress = 0;
			let words = [app.t('labels.stillWorking'), app.t('labels.mostlyDone'), app.t('labels.aBitMore'), app.t('labels.almostDone')];
			let interval = setInterval(() => {
				if (!this.#button.disabled || loadingProgress === words.length) {
					clearInterval(interval);
					return;
				}
				
				this.innerHTML = words[loadingProgress];
				loadingProgress++;
			}, 10000);
		}
	}

	reset() {
		this.removeAttribute('disabled');
		this.innerHTML = this.#initialSlot;
	}
});
