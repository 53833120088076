import Store, { INIT_STATE } from './store';

const INIT_HEALTH_STATE = 'INIT_HEALTH_STATE';
const GET_FIRMWARE = 'GET_FIRMWARE';
const GET_UPTIME = 'GET_UPTIME';
const GET_UPDATES = 'GET_UPDATES';
const SET_POLLING_UPDATES = 'SET_POLLING_UPDATES';
const START_CHECKING_FOR_UPDATES = 'START_CHECKING_FOR_UPDATES';
const HAS_CHECKED_FOR_UPDATES = 'HAS_CHECKED_FOR_UPDATES';
const GET_UPDATE = 'GET_UPDATE';
const UPDATE = 'UPDATE';
const ACKNOWLEDGE_UPDATE = 'ACKNOWLEDGE_UPDATE';
const RESTART = 'RESTART';
const POLL_HEALTH = 'POLL_HEALTH';
const SHUT_DOWN = 'SHUT_DOWN';
const POWERED_OFF = 'POWERED_OFF';
const GET_TIMEZONES = 'GET_TIMEZONES';
const GET_USERS = 'GET_USERS';
const GET_USER = 'GET_USER';
const CREATE_USERS = 'CREATE_USERS';
const DELETE_USER = 'DELETE_USER';
const DELETING_USER = 'DELETING_USER';
const DELETE_USERS_FAILED = 'DELETE_USERS_FAILED';
const UPDATE_USER = 'UPDATE_USER';
const GET_REMOTE_SUPPORT = 'GET_REMOTE_SUPPORT';
const SET_REMOTE_SUPPORT = 'SET_REMOTE_SUPPORT';

class Node extends Store {
	constructor() {
		const initialState = {
			isUpdating: false,
			isAcknowledgeUpdate: false,
			isRestarting: false,
			isShuttingDown: false,
			isShutDown: false,
			isPollingUpdates: false,
			isCheckingForUpdates: false,
			uptime: null,
			software: {},
			health: null,
			timezones: null,
			users: null,
			user: null,
			remoteSupport: null
		};
		super();
		this.setState(initialState, INIT_STATE);
		this.pollingUpdate = null;
		this.pollingUpdates = null;
		this.updatesRequest = null;
		this.updatesPollingTimeout = 5000;
		this.remainingUpdatesPollingRetries = null;
		this.pollingHealth = null;
		this.timeFetch = null;
	}

	initHealthState() {
		this.setState({ health: null }, INIT_HEALTH_STATE);
	}
	
	getProduct() {
		return this.get('/api/node/system')
			.then((response) => {
				return response.data;
			});
	}

	getUptime() {
		return this.get('/cake/system/info.json')
			.then((response) => {
				let uptime = response.data.systemInfo.uptime.replace('up', '');
				this.setState({ uptime: uptime }, GET_UPTIME);
				return uptime;
			});
	}

	// Firmware

	fetchFirmware() {
		return this.get('/api/node/software/installed')
			.then((response) => {
				let software = this.getStateProperty('software');
				let firmware = response.data;
				_.assign(software, { firmware: firmware });
				this.setState({ software: software }, GET_FIRMWARE);
				return firmware;
			});
	}

	getFirmware() {
		let software = this.getStateProperty('software');
		if (software.firmware !== undefined) {
			return this.createPromise(software.firmware);
		}

		return this.fetchFirmware();
	}

	// Updates

	hasUpdates() {
		let software = this.getStateProperty('software');
		if (_.isNull(software)) {
			return;
		}
		
		return software.updates !== false && !_.isEmpty(software.updates);
	}

	fetchUpdates() {
		return this.get('/api/node/software/available')
			.then((response) => {
				let updates = response.data;
				let software = this.getStateProperty('software');
				_.assign(software, { updates: updates });
				this.setState({ software: software }, GET_UPDATES);
				return updates;
			})
			.catch((error) => {
				if (_.isNull(this.remainingUpdatesPollingRetries)) {
					this.remainingUpdatesPollingRetries = 3;
					this.setState({ isPollingUpdates: true }, SET_POLLING_UPDATES);
					setTimeout(this.startPollingUpdates.bind(this), this.updatesPollingTimeout);
				} else if (this.remainingUpdatesPollingRetries > 0) {
					this.remainingUpdatesPollingRetries--;
				}

				let updates = false;
				let software = this.getStateProperty('software');
				_.assign(software, { updates: updates });
				this.setState({ software: software }, GET_UPDATES);
				throw error;
			});
	}

	isPollingUpdates() {
		return this.getStateProperty('isPollingUpdates');
	}

	startPollingUpdates() {
		if (this.getStateProperty('isRestarting') !== true && this.getStateProperty('isShuttingDown') !== true && this.getStateProperty('isUpdating') !== true) {
			this.setState({ isPollingUpdates: true }, SET_POLLING_UPDATES);
			this.updatesRequest = this.fetchUpdates()
				.then(poll.bind(this))
				.catch(poll.bind(this));
		} else {
			this.updatesRequest = null;
			this.setState({ isPollingUpdates: false }, SET_POLLING_UPDATES);
			poll.call(this);
		}

		function poll() {
			if (this.remainingUpdatesPollingRetries === 0) {
				this.stopPollingUpdates();
				return;
			}

			this.pollingUpdates = setTimeout(this.startPollingUpdates.bind(this), this.updatesPollingTimeout);
		}
	}

	stopPollingUpdates() {
		if (!_.isEmpty(this.updatesRequest)) {
			httpClient.cancelRequests(this.updatesRequest);
			this.updatesRequest = null;
		}

		if (this.pollingUpdates) {
			clearTimeout(this.pollingUpdates);
			this.pollingUpdates = null;
		}
		this.setState({ isPollingUpdates: false }, SET_POLLING_UPDATES);
	}

	checkAvailableUpdates() {
		this.setState({ isCheckingForUpdates: true }, START_CHECKING_FOR_UPDATES);
		return httpClient.post('api/node/software/available')
			.then(() => {
				let software = this.getStateProperty('software');
				_.assign(software, { updates: null });
				this.setState({ software: software, isCheckingForUpdates: false }, HAS_CHECKED_FOR_UPDATES);
				return this.fetchUpdates();
			})
			.catch((error) => {
				this.setState({ isCheckingForUpdates: false }, HAS_CHECKED_FOR_UPDATES);
				throw error;
			});
	}

	getUpdates() {
		let software = this.getStateProperty('software');
		if (software.updates !== undefined) {
			return this.createPromise(software.updates);
		}

		return this.fetchUpdates();
	}

	fetchUpdate() {
		return this.get('/api/node/software/update')
			.then((response) => {
				let update = response.data;
				let isUpdating = (!_.isEmpty(update.job) && !update.job.isAcknowledged);
				let software = this.getStateProperty('software');
				_.assign(software, { update: update });
				this.setState({ software: software, isUpdating: isUpdating }, GET_UPDATE);
				return update;
			});
	}

	getUpdate() {
		let software = this.getStateProperty('software');
		if (software.update !== undefined) {
			return this.createPromise(software.update);
		}

		return this.fetchUpdate();
	}

	startPollingUpdate() {
		this.stopPollingUpdate();

		let software = this.getStateProperty('software');
		if (software && !_.isEmpty(software.update) && !_.isEmpty(software.update.job) && _.includes(['succeeded', 'failed', 'canceled'], software.update.job.status.toLowerCase())) {
			return;
		}

		this.pollingUpdate = setTimeout((() => {
			this.fetchUpdate()
				.then(() => {
					this.startPollingUpdate();
				})
				.catch((error) => {
					this.startPollingUpdate();
					throw error;
				});
		}).bind(this), 5000);
	}

	stopPollingUpdate() {
		if (this.pollingUpdate) {
			clearTimeout(this.pollingUpdate);
			this.pollingUpdate = null;
		}
	}

	update(toRelease) {
		return this.post(
			'/api/node/software/update',
			toRelease
		)
			.then(() => {
				const software = this.getStateProperty('software');
				this.setState({ isUpdating: true, software: { ...software, update: {} } }, UPDATE);
				return this.fetchUpdate();
			})
			.catch((error) => {
				this.setState({ isUpdating: false }, UPDATE);
				throw error;
			});
	}

	acknowledgeUpdate() {
		return this.put('/api/node/software/update/acknowledge')
			.then(() => {
				this.setState({ isUpdating: false, isAcknowledgeUpdate: true }, ACKNOWLEDGE_UPDATE);
			});
	}

	// Power state

	startPollingHealth() {
		let remainingRetries = 30;
		this.pollingHealth = httpClient.poll(
			'/api/node/health',
			null,
			(response) => {
				let { state } = response.data;
				this.setState({ health: response.data }, POLL_HEALTH);
				if (_.includes(['initializing', 'ready'], state.toLowerCase())) {
					this.setState({ isRestarting: false }, RESTART);
				}
				if (state.toLowerCase() === 'ready') {
					this.stopPollingHealth();
				}
			},
			(error) => {
				remainingRetries--;
				if (remainingRetries <= 0) {
					this.stopPollingHealth();
					this.setState({ health: false, isRestarting: false }, RESTART);
				} else {
					this.setState({ health: {} }, POLL_HEALTH);
				}
			}
		);
	}

	pollUntilAvailable(callback) {	
		let polling = httpClient.poll(
			'/api/node/health',
			{
				method: 'head'
			},
			() => {
				polling.cancel();
				callback();
			},
			(error) => {
				if (httpClient.isCancel(error)) {
					return;
				}

				console.log('Waiting to be available...');
				return error;
			}
		);
		return polling;
	}

	stopPollingHealth() {
		if (this.pollingHealth) {
			this.pollingHealth.cancel();
			this.pollingHealth = null;
		}
	}

	waitToBeAvailable() {
		this.head('/api/node/health')
			.then(() => {
				this.startPollingHealth();
			})
			.catch((error) => {
				console.log('Waiting to power on...');
				setTimeout(this.waitToBeAvailable.bind(this), 5000);
			});
	}

	waitToBeUnavailable() {
		this.head('/api/node/health')
			.then(() => {
				setTimeout(this.waitToBeUnavailable.bind(this), 5000);
			})
			.catch((error) => {
				if (this.getStateProperty('isRestarting')) {
					this.waitToBeAvailable();
				}
				if (this.getStateProperty('isShuttingDown')) {
					this.setState({ isShutDown: true }, POWERED_OFF);
				}
			});
	}

	restart() {
		return this.put('/api/node/reboot')
			.then(() => {
				this.setState({ isRestarting: true }, RESTART);
				return this.waitToBeUnavailable();
			});
	}
	
	shutDown() {
		return this.put('/api/node/poweroff')
			.then(() => {
				this.setState({ isShuttingDown: true }, SHUT_DOWN);
				return this.waitToBeUnavailable();
			});
	}

	// Date & time
	
	fetchTime() {
		if (this.timeFetch) {
			return this.timeFetch;
		}
		
		this.timeFetch = this.get('/api/node/time')
			.then((response) => {
				this.timeFetch = null;
				return response.data;
			})
			.catch((error) => {
				this.timeFetch = null;
				return false;
			});
		return this.timeFetch;
	}
	
	fetchTimezones() {
		let timezones;
		return this.get('/api/node/time/timezones')
			.then((response) => {
				timezones = response.data;
			})
			.catch((error) => {
				timezones = false;
			})
			.then(() => {
				this.setState({ timezones: timezones }, GET_TIMEZONES);
				return timezones;
			});
	}
	
	hasTimezones() {
		let timezones = this.getStateProperty('timezones');
		if (_.isNull(timezones)) {
			return;
		}
		
		return timezones !== false && !_.isEmpty(timezones);
	}
	getTimezones() {
		let timezones = this.getStateProperty('timezones');
		if (!_.isNull(timezones)) {
			return this.createPromise(timezones);
		}
		
		return this.fetchTimezones();
	}
	
	updateTime(data) {
		return this.put(
			'/api/node/time',
			data
		)
			.then((response) => {
				if (data.ntp && !_.isEmpty(data.ntp.servers)) {
					return this.syncNtp();
				}
				
				return response.data;
			});
	}
	
	updateTimezone(data) {
		return this.put(
			'/api/node/time',
			data
		)
			.then((response) => {
				return response.data;
			});
	}
	
	syncNtp() {
		return this.put(
			'/api/node/time/ntp/sync-clock',
			{
				'requireReboot': false
			}
		)
			.then(() => {
				return this.fetchTime();
			});
	}

	// users
	fetchUsers() {
		let users;
		return this.get(
			'api/auth/users'
		)
			.then((response) => {
				users = response.data;
			})
			.catch((error) => {
				users = false;
			})
			.then(() => {
				this.setState({ users: users }, GET_USERS);
				return users;
			});
	}

	fetchUser(id) {
		let user;
		return this.get(`/api/auth/users/${id}`)
			.then((response) => {
				user = response.data;
			})
			.catch((error) => {
				user = false;
			})
			.then(() => {
				this.setState({ user: user }, GET_USER);
				return user;
			});
	}

	hasUsers() {
		let users = this.getStateProperty('users');
		if (_.isNull(users)) {
			return;
		}

		return users !== false && !_.isEmpty(users);
	}

	getUsers(id) {
		let users = this.getStateProperty('users');
		if (id === undefined) {
			if (!_.isNull(users)) {
				return this.createPromise(users);
			}

			return this.fetchUsers();
		}

		return this.fetchUser(id);
	}

	getUser() {
		let user = this.getStateProperty('user');
		if (!_.isNull(user)) {
			return user;
		}

		return false;
	}

	createUser(user) {
		return this.post(
			'/api/auth/users',
			user
		)
			.then((response) => {
				let users = this.getStateProperty('users');
				users.push(response.data);
				this.setState({ users: users }, CREATE_USERS);
				return response.data;
			});
	}

	updateUser(user, auth) {
		return this.patch(
			`/api/auth/users/${user.id}`,
			user,
			{
				headers: { 'continuous-auth': auth }
			}
		)
			.then((response) => {
				let user = response.data;
				user.isSuper = _.includes(['administrator'], user.role.toLowerCase());
				if (user.id === app.user.id) {
					localStorage.setItem('user', JSON.stringify(user));
					app.initializeUser();
				}
				
				let users = this.getStateProperty('users');
				users = _.reject(users, { id: user.id });
				users.push(user);
				this.setState({ users: users }, UPDATE_USER);
			});
	}

	deleteUser(id, auth) {
		this.setState({ users: _.map(this.getStateProperty('users'), (user) => {
			if (user.id === id) {
				user.isDeleting = true;
			}

			return user;
		}) }, DELETING_USER);

		return this.delete(
			`/api/auth/users/${id}`,
			{
				headers: { 'continuous-auth': auth }
			}
		)
			.then(() => {
				let users = _.reject(this.getStateProperty('users'), { id: id });
				this.setState({ users: users }, DELETE_USER);
			})
			.catch((error) => {
				let users = _.map(this.getStateProperty('users'), (user) => {
					if (user.id === id) {
						user.isDeleting = false;
					}

					return user;
				});
				this.setState({ users: users }, DELETE_USERS_FAILED);
				throw error;
			});
	}

	// Remote support

	getRemoteSupport() {
		let remoteSupport = this.getStateProperty('remoteSupport');
		if (!_.isEmpty(remoteSupport)) {
			return this.createPromise(remoteSupport);
		}

		return this.fetchRemoteSupport();
	}

	fetchRemoteSupport() {
		let remoteSupport;
		return this.get('/api/node/remote-support')
			.then((response) => {
				remoteSupport = response.data;
				this.setState({ remoteSupport: remoteSupport }, GET_REMOTE_SUPPORT);
				return remoteSupport;
			})
			.catch((error) => {
				remoteSupport = false;
				this.setState({ remoteSupport: remoteSupport }, GET_REMOTE_SUPPORT);
				return remoteSupport;
			});
	}

	toggleRemoteSupport(isEnabled, auth) {
		return this.put(
			'/api/node/remote-support',
			{
				action: isEnabled ? 'enable' : 'disable'
			},
			{
				headers: { 'continuous-auth': auth }
			}
		)
			.then((response) => {
				let remoteSupport = response.data;
				this.setState({ remoteSupport: remoteSupport }, SET_REMOTE_SUPPORT);
				return remoteSupport;
			});
	}
}

export default new Node();
