$.fn.dataTable.ext.type.order['size-pre'] = (data) => {
	data = data.replace('&nbsp;', ' ');
	let matches = data.match( /^(\d+(?:\.\d+)?)\s*([a-z]+)/i );
	let multipliers = {
		b: 1,
		bytes: 1,
		kb: 1024,
		mb: 1048576,
		gb: 1073741824,
		tb: 1099511627776,
		pb: 1125899906842624
	};
	
	if (matches) {
		let multiplier = multipliers[matches[2].toLowerCase()];
		return parseFloat( matches[1] ) * multiplier;
	} else {
		return -1;
	}
};

$.fn.dataTable.ext.type.order['frequency-pre'] = (data) => {
	data = data.replace('&nbsp;', ' ');
	let matches = data.match( /^(\d+(?:\.\d+)?)\s*([a-z]+)/i );
	let multipliers = {
		hz: 1,
		khz: 1024,
		mhz: 1048576,
		ghz: 1073741824,
		thz: 1099511627776
	};
	
	if (matches) {
		let multiplier = multipliers[matches[2].toLowerCase()];
		return parseFloat( matches[1] ) * multiplier;
	} else {
		return -1;
	}
};

$.fn.dataTable.ext.type.order['time-pre'] = (data) => {
	let $data = $(data).filter('.timestamp');
	let timestamp = moment.unix($data.text());
	if (!timestamp.isValid()) {
		return -1;
	}

	return parseInt(timestamp.format('X'));
};

$.fn.dataTable.ext.type.order['duration-pre'] = (data) => {
	return parseInt($(data).filter('.duration').text());
};

$.fn.dataTable.ext.type.order['used-pre'] = (data) => {
	return parseInt($(data).filter('.used').text());
};

$.fn.dataTableExt.afnFiltering.push((settings, data) => {
	let $module = $(settings.nTable).closest('.list-details');
	if (!_.includes(['virtual-machines'], $module.attr('id'))) {
		return true;
	}
	
	let filterValues = ($.hash.get('filter-virtual-machines') ? JSON.parse($.hash.get('filter-virtual-machines')) : []);

	// Protection status
	let protectionFilters = _.filter(filterValues, (filterItem) => { return _.startsWith(filterItem, 'p-');	});
	let filteredProtection = _.isEmpty(protectionFilters) || _.some(protectionFilters, (filterItem) => { return _.includes(data[9], filterItem); });

	// SLA policy
	let slaFilters = _.filter(filterValues, (filterItem) => { return _.startsWith(filterItem, 's-'); });
	let filteredSla = _.isEmpty(protectionFilters) || !_.includes(protectionFilters, 'p-1') || _.isEmpty(slaFilters) || _.some(slaFilters, (filterItem) => { return _.includes(data[7], filterItem); }) || (filteredProtection && _.includes(data[8], 'p-0'));

	// Power state
	let powerStateFilters = _.filter(filterValues, (filterItem) => { return _.startsWith(filterItem, 'w-');	});
	let filteredPowerState = _.isEmpty(powerStateFilters) || _.some(powerStateFilters, (filterItem) => { return _.includes(data[1], filterItem); });

	// Is clone
	let cloneStatusFilters = _.filter(filterValues, (filterItem) => { return _.startsWith(filterItem, 'c-');	});
	let filteredCloneStatus = _.isEmpty(cloneStatusFilters) || _.some(cloneStatusFilters, (filterItem) => { return _.includes(data[9], filterItem); });

	return filteredPowerState && filteredProtection && filteredCloneStatus && filteredSla;
});

$.fn.dataTableExt.afnFiltering.push((settings, data) => {
	let $module = $(settings.nTable).closest('.list-details');
	if (!_.includes(['hosts'], $module.attr('id'))) {
		return true;
	}
	
	let filterValue = ($.hash.get('filter-hosts') ? JSON.parse($.hash.get('filter-hosts')) : -1);
	if (filterValue === -1) {
		return true;
	}
	
	if (filterValue === 0) {
		return data[5].toLowerCase() === 'disconnected' || data[5].toLowerCase() === 'not responding';
	}

	if (filterValue === 1) {
		return data[5].toLowerCase() === 'connected';
	}

	return true;
});
