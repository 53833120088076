import X from '../x';
import template from './switch.html';

customElements.define('x-switch', class Switch extends X {
	#checkbox;
	#initialChecked;
	#initialDisabled;
	#template = template;
	
	static get observedAttributes() {
		return ['checked', 'disabled'];
	}
	
	constructor() {
		super();
		
		this.#render();
		this.#attachEventListeners();
	}
	
	formResetCallback() {
		this.#checkbox.checked = this.#initialChecked;
		this.removeAttribute('disabled');
		if (this.#initialDisabled) {
			this.toggleAttribute('disabled');
		}
	}
	
	attributeChangedCallback(name, oldValue, newValue) {
		if (name === 'checked') {
			this.#checkbox.checked = (newValue !== null);
		}
		if (name === 'disabled') {
			this.#checkbox.disabled = (newValue !== null);
			this.shadowRoot.querySelector('.switch').classList.add('disabled');
		}
	}
	
	restore() {
		this.#checkbox.checked = !this.#checkbox.checked;
		this.#checkbox.disabled = false;
		this.shadowRoot.querySelector('.spinner-border').classList.add('d-none');
	}
	
	start() {
		this.#checkbox.disabled = true;
		this.shadowRoot.querySelector('.spinner-border').classList.remove('d-none');
	}
	
	stop() {
		this.#checkbox.disabled = false;
		this.shadowRoot.querySelector('.spinner-border').classList.add('d-none');
	}
	
	get checked() {
		return this.#checkbox.checked;
	}
	
	set checked(value) {
		this.removeAttribute('checked');
		if (String(value) === 'true') {
			this.toggleAttribute('checked');
		}
	}
	
	get disabled() {
		return this.hasAttribute('disabled');
	}
	
	set disabled(value) {
		this.removeAttribute('disabled');
		if (String(value) === 'true') {
			this.toggleAttribute('disabled');
		}
	}
	
	#render() {
		this.style.display = 'inline-block';
		let template = document.createElement('template');
		template.innerHTML = this.#template;
		this.shadowRoot.appendChild(template.content.cloneNode(true));
		
		this.#checkbox = this.shadowRoot.querySelector('input');
		this.#initialChecked = this.hasAttribute('checked');
		this.#initialDisabled = this.hasAttribute('disabled');
		this.shadowRoot.querySelector('.switch').classList.toggle('disabled', this.#checkbox.disabled);
		if (this.hasAttribute('on-label')) {
			this.shadowRoot.querySelector('label').append(this.getAttribute('on-label'));
		}
		if (this.hasAttribute('off-label')) {
			this.shadowRoot.querySelector('label').prepend(this.getAttribute('off-label'));
		}
		if (this.hasAttribute('help-text')) {
			this.shadowRoot.querySelector('label').title = this.getAttribute('help-text');
		}
	}
	
	#attachEventListeners() {
		this.#checkbox.addEventListener('change', (event) => {
			this.dispatchEvent(new event.constructor(event.type, event));
		});
		
		this.shadowRoot.querySelectorAll('.switch').forEach((trigger) => {
			new bootstrap.Tooltip(trigger);
		});
	}
});
