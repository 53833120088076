import X from '../x';
import template from './toast.html';

customElements.define('x-notifier', class Notifier extends X {
	#toast = template;
	#clock = null;
	
	constructor() {
		super();
		
		this.#render();
	}
	
	setClock(clock) {
		this.#clock = clock;
	}
	
	add(config) {
		config = config || {};
		let toast;
		this.shadowRoot.querySelector('.toast-container').insertAdjacentHTML('beforeend', this.#toast);
		this.shadowRoot.querySelectorAll('.toast:last-child').forEach((trigger) => {
			toast = new bootstrap.Toast(trigger, { autohide: false, animation: false, delay: 8000 });
			
			toast._config.hide = (config.type.toLowerCase() === 'success' ? true : false);
			switch (config.type.toLowerCase()) {
				case 'success':
					toast._config.className = 'bd-green-100';
					toast._config.iconClassName = 'fa-face-grin text-success';
					break;
				case 'warning':
					toast._config.className = 'bd-yellow-100';
					toast._config.iconClassName = 'fa-face-grin-beam-sweat text-warning';
					break;
				case 'error':
					toast._config.className = 'bd-red-100';
					toast._config.iconClassName = 'fa-face-frown text-danger';
					break;
				case 'info':
					toast._config.className = 'bd-blue-100';
					toast._config.iconClassName = 'fa-face-thinking text-primary';
					break;
			}
			toast._config = Object.assign({}, toast._config, config);
			
			toast._element.classList.add(toast._config.className);
			toast._element.querySelector('.toast-body .fas').classList.add(...toast._config.iconClassName.split(' '));
			toast._element.querySelector('.toast-body p').innerHTML = toast._config.text;
			if (this.#clock) {
				toast._element.querySelector('.toast-body small').innerHTML = this.#clock.getCurrentTime().format('HH:mm:ss - D MMM, YYYY');
			}
			if (toast._config.closer === false) {
				toast._element.querySelector('.btn-close').remove();
			}
			this.#attachEventListeners(toast);
			toast.show();
		});
		
		return toast;
	}
	
	#render() {
		let template = document.createElement('template');
		template.innerHTML = `<div class="toast-container position-fixed end-0 bottom-0 p-2"></div>`;
		this.shadowRoot.appendChild(template.content.cloneNode(true));
	}
	
	#attachEventListeners(toast) {
		toast._timeout = null;
		
		if (toast._config.closer !== false) {
			toast._element.querySelector('.btn-close').addEventListener('click', (event) => {
				hide();
			});
		}
		
		toast._element.addEventListener('mouseenter', (event) => {
			stopTimeout();
		});
		
		toast._element.addEventListener('mouseleave', (event) => {
			if (toast._config.hide === false) {
				return;
			}
			
			startTimeout();
		});
		
		toast._element.addEventListener('shown.bs.toast', (event) => {
			if (toast._config.callbacks && typeof toast._config.callbacks.afterShown === 'function') {
				toast._config.callbacks.afterShown(event);
			}
			
			if (toast._config.hide === false) {
				return;
			}
			
			startTimeout();
		});
		
		toast._element.addEventListener('hide.bs.toast', (event) => {
			if (toast._config.callbacks && typeof toast._config.callbacks.beforeHide === 'function') {
				toast._config.callbacks.beforeHide(event);
			}
		});
		
		toast._element.addEventListener('hidden.bs.toast', (event) => {
			toast._element.remove();
		});
		
		function startTimeout() {
			toast._timeout = setTimeout(() => {
				hide();
			}, toast._config.delay);
		}
		
		function stopTimeout() {
			clearTimeout(toast._timeout);
			toast._timeout = null;
		}
		
		function hide() {
			toast._element.classList.remove('animate__bounceInRight');
			toast._element.classList.add('animate__bounceOutRight');
			setTimeout(() => {
				toast.hide();
			}, 500);
		}
	}
});
