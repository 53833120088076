import X from '../x';
import template from './progress.html';

customElements.define('x-progress', class Progress extends X {
	#slot;
	#template = template;
	
	constructor() {
		super();
		
		this.#render();
		this.#attachEventListeners();
	}
	
	#setColor(progressBar, color, brightness) {
		color = color || 'blue';
		brightness = brightness || '500';
		let classList = progressBar.classList;
		let classesToRemove = Array.from(classList).filter((className) => { return className.startsWith('bd-'); });
		if (classesToRemove.length > 0) {
			classList.remove(...classesToRemove);
		}
		classList.add(`bd-${color}-${brightness}`);
	}
	
	#render() {
		this.style.display = 'block';
		let template = document.createElement('template');
		template.innerHTML = '<div class="progress-stacked"></div><slot class="d-none"></slot>';
		this.shadowRoot.appendChild(template.content.cloneNode(true));
		
		this.#slot = this.shadowRoot.querySelector('slot');
		this.#renderSlots();
	}
	
	#renderSlots() {
		let progressStacked = this.shadowRoot.querySelector('.progress-stacked');
		progressStacked.innerHTML = '';
		this.#slot.assignedElements().forEach((element) => {
			if (element.nodeName.toLowerCase() === 'progress') {
				let template = document.createElement('template');
				template.innerHTML = this.#template;
				let progress = template.content.querySelector('.progress');
				progress.style.width = `${element.getAttribute('value')}%`;
				let progressBar = progress.querySelector('.progress-bar');
				this.#setColor(progressBar, element.getAttribute('color'), element.getAttribute('brightness'));
				progressBar.classList.remove('progress-bar-striped', 'progress-bar-animated');
				if (element.hasAttribute('animated')) {
					progressBar.classList.add('progress-bar-striped', 'progress-bar-animated');
				}
				progressStacked.appendChild(template.content);
			}
		});
	}
	
	#attachEventListeners() {
		this.#slot.addEventListener('slotchange', (event) => {
			this.#renderSlots();
		});
	}
	
	setValue(value, progressIndex) {
		progressIndex = progressIndex || 1;
		this.querySelector(`progress:nth-of-type(${progressIndex})`)?.setAttribute('value', value);
		this.#renderSlots();
	}
});
