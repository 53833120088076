import Central from 'store/central';

let subscription = null;
let callbackCollection = [];

const getDetails = () => {
	return Central.getDetails();
};

const canConnect = () => {
	return Central.getHealth()
		.then((status) => {
			return (status === 'ok');
		})
		.catch((error) => {
			throw error;
		});
};

const refresh = () => {
	return Central.refresh();
};

const link = (data) => {
	return Central.link(data);
};

const unlink = () => {
	return Central.unlink();
};

const setMaintenance = (maintenance) => {
	return Central.setMaintenance(maintenance);
};

const enableSerenity = () => {
	return Central.toggleSerenity(true);
};

const disableSerenity = (auth) => {
	return Central.toggleSerenity(false, auth);
};

const handleSubscription = (store) => {
	if (!store) {
		return;
	}
	
	let state = store.state;
	if (_.isNull(state.central)) {
		return;
	}
	
	_.each(callbackCollection, (callback) => {
		callback(state);
	});
};

const unsubscribe = () => {
	if (subscription) {
		subscription.unsubscribe();
		subscription = null;
	}
};

const subscribe = (callbacks) => {
	callbackCollection = _.concat(callbackCollection, callbacks);
	unsubscribe();
	subscription = Central.subscribeToProperties(['central'], handleSubscription);
};

export {
	getDetails,
	canConnect,
	refresh,
	link,
	unlink,
	setMaintenance,
	enableSerenity,
	disableSerenity,
	subscribe,
	unsubscribe
};
