import 'jquery-serializejson';
import 'jquery.formvalidation';
import 'jquery.formvalidation.bootstrap';
import 'common/formvalidation_extra';

$.fn.invalidate = function (response, defaultMessage) {
	response = response || {};
	defaultMessage = defaultMessage || '';
	if (response.succeeded === true) {
		return;
	}
	
	let message = (response?.response?.data?.message || response?.response?.data?.detail || response?.message || defaultMessage);
	_.each(this, (element) => {
		let $form = $(element);
		if (!$form.is('form')) {
			return;
		}
		
		$form.data('is-submitting', false);
		_.each(response.invalidFields, (message, field) => {
			let $field = $form.find(`.${_.kebabCase(field)}`);
			$form.formValidation('updateMessage', $field, 'blank', message);
			$form.formValidation('updateStatus', $field, 'INVALID', 'blank');
		});
		if (!_.isEmpty(message)) {
			app.notifier.add({ text: message, type: 'error' });
		}

		_.each($form.find('x-button'), (button) => {
			button.reset();
		});
		$form.find('.has-error:first x-input:visible:first').focus();
	});
	
	return this;
};

$.fn.reset = function () {
	_.each(this, (element) => {
		let $form = $(element);
		if (!$form.is('form')) {
			return;
		}
		
		$form.trigger('reset');
		$form.clear();
	});
	
	return this;
};

$.fn.clear = function () {
	_.each(this, (element) => {
		let $form = $(element);
		if (!$form.is('form')) {
			return;
		}
		
		$form.data('is-submitting', false);
		$form.formValidation('resetForm');
		_.each($form.find('x-button'), (button) => {
			button.reset();
		});
		$form.find('x-input:visible:first').focus();
	});
	
	return this;
};

$.fn.beforeSubmit = function () {
	_.each(this, (element) => {
		let $form = $(element);
		if (!$form.is('form')) {
			return;
		}
		
		$form.data('is-submitting', true);
		$form.find('.form-group').removeClass('has-error');
		_.first($form.find('x-button[type="submit"]'))?.setState('loading');
		_.each($form.find('x-button'), (button) => {
			button.disabled = true;
		});
	});
	
	return this;
};

$.fn.afterSubmit = function () {
	_.each(this, (element) => {
		let $form = $(element);
		if (!$form.is('form')) {
			return;
		}
		
		if ($form.hasClass('modal')) {
			$form.data('is-submitting', false);
			$form.modal('hide');
			return;
		}

		$form.clear();
	});
	
	return this;
};
