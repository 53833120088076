import X from '../x';
import template from './checkbox.html';

customElements.define('x-checkbox', class Checkbox extends X {
	#checkbox;
	#initialChecked;
	#initialIndeterminate;
	#initialDisabled;
	#template = template;
	
	static get observedAttributes() {
		return ['label', 'checked', 'indeterminate', 'disabled'];
	}
	
	constructor() {
		super();
		
		this.#render();
		this.#attachEventListeners();
	}
	
	formResetCallback() {
		this.removeAttribute('checked');
		if (this.#initialChecked) {
			this.toggleAttribute('checked');
		}
		this.removeAttribute('indeterminate');
		if (this.#initialIndeterminate) {
			this.toggleAttribute('indeterminate');
		}
		this.removeAttribute('disabled');
		if (this.#initialDisabled) {
			this.toggleAttribute('disabled');
		}
	}
	
	attributeChangedCallback(name, oldValue, newValue) {
		if (name === 'label') {
			this.shadowRoot.querySelector('label').append(newValue);
		}
		if (name === 'checked') {
			this.#checkbox.checked = (newValue !== null);
		}
		if (name === 'indeterminate') {
			this.#checkbox.indeterminate = (newValue !== null);
		}
		if (name === 'disabled') {
			this.#checkbox.disabled = (newValue !== null);
		}
	}
	
	get name() {
		return this.getAttribute('name');
	}
	
	get type() {
		return 'checkbox';
	}
	
	get nodeName() {
		return 'INPUT';
	}
	
	get value() {
		if (this.checked) {
			return this.getAttribute('value');
		}
		
		return null;
	}
	
	get checked() {
		return this.#checkbox.checked;
	}
	
	set checked(value) {
		this.removeAttribute('checked');
		if (String(value) === 'true') {
			this.toggleAttribute('checked');
		}
	}
	
	get indeterminate() {
		return this.hasAttribute('indeterminate');
	}
	
	set indeterminate(value) {
		this.removeAttribute('indeterminate');
		if (String(value) === 'true') {
			this.toggleAttribute('indeterminate');
		}
	}
	
	get disabled() {
		return this.hasAttribute('disabled');
	}
	
	set disabled(value) {
		this.removeAttribute('disabled');
		if (String(value) === 'true') {
			this.toggleAttribute('disabled');
		}
	}
	
	#render() {
		let template = document.createElement('template');
		template.innerHTML = this.#template;
		this.shadowRoot.appendChild(template.content.cloneNode(true));

		this.#checkbox = this.shadowRoot.querySelector('input[type="checkbox"]');
		this.#initialChecked = this.hasAttribute('checked');
		this.#initialIndeterminate = this.hasAttribute('indeterminate');
		this.#initialDisabled = this.hasAttribute('disabled');
		
		
		if (this.hasAttribute('boolean')) {
			this.setAttribute('name', `${this.getAttribute('name')}:boolean`);
			let value = this.getAttribute('value') || '';
			let checkedValue = (value === '' || value === 'true' || value !== 'false');
			let uncheckedValue = !checkedValue;
			this.setAttribute('value', checkedValue.toString());
			this.setAttribute('data-unchecked-value', uncheckedValue.toString());
		}
		
		if (this.hasAttribute('gapless')) {
			this.shadowRoot.querySelector('.form-group').classList.add('mb-0');
		}
	}

	#attachEventListeners() {
		this.#checkbox.addEventListener('keypress', (event) => {
			if (event.code.toLowerCase() === 'enter') {
				let form = this.closest('form');
				if (form) {
					form.dispatchEvent(new event.constructor('submit', event));
				}
			}
		});
		
		this.#checkbox.addEventListener('keyup', (event) => {
			this.dispatchEvent(new event.constructor('change', event));
		});
		
		this.#checkbox.addEventListener('change', (event) => {
			this.removeAttribute('checked');
			if (this.#checkbox.checked) {
				this.toggleAttribute('checked');
			}
			this.dispatchEvent(new event.constructor('change', event));
		});
	}
});
