import { ObservableStore } from '@codewithdan/observable-store';
import { ReduxDevToolsExtension } from '@codewithdan/observable-store-extensions';

ObservableStore.globalSettings = {
	trackStateHistory: true,
	logStateChanges: false
};
ObservableStore.addExtension(new ReduxDevToolsExtension());

export const INIT_STATE = 'INIT_STATE';

class Store extends ObservableStore {
	constructor(settings) {
		super(settings);
		let runningOperations = this.getStateProperty('runningOperations');

		if (!runningOperations) {
			runningOperations = localStorage.getItem('runningOperations') ? JSON.parse(localStorage.getItem('runningOperations')) : {};
		}

		if (!(runningOperations?.[this.constructor.name] ?? false)) {
			runningOperations[this.constructor.name] = {};
		}

		// Check if tasks are still running (after refresh)
		_.each(runningOperations[this.constructor.name], (operation, key) => {
			this.checkRunningOperation(operation, key);
		});

		this.setState({ runningOperations: runningOperations }, INIT_STATE);
	}

	subscribeToProperties(properties, callback) {
		let changedProperties = [];

		let subscription = this.globalStateWithPropertyChanges.subscribe((store) => {
			if (!store) {
				return;
			}
			
			let stateChanges = store.stateChanges;
			
			if (!_.some(properties, (property) => {
				if (_.has(stateChanges, property) && !_.includes(changedProperties, property)) {
					changedProperties.push(property);
					return true;
				}

				return false;
			})) {
				return;
			}
			
			changedProperties = [];
			callback(store);
		});

		callback({ state: this.getState() });
		return subscription;
	}

	createPromise(result, err) {
		return new Promise((resolve, reject) => {
			return err ? reject(err) : resolve(result);
		});
	}

	setState(newState, action, dispatchState, deepCloneState) {
		let currentState = this.getState();
		if (_.isNull(currentState) || _.some(newState, (value, key) => {
			return value === false || !_.isEqual(value, currentState[key]);
		})) {
			super.setState(newState, action, dispatchState, deepCloneState);
		}
	}

	hasRunningOperations(key) {
		let runningOperations = this.getStateProperty('runningOperations');
		if (_.isNull(runningOperations)) {
			return;
		}
		
		return !_.isEmpty(runningOperations[this.constructor.name][key]);
	}

	checkRunningOperation(jobId, key) {
		let runningOperations = this.getStateProperty('runningOperations');

		runningOperations[this.constructor.name][key] = jobId;
		localStorage.setItem('runningOperations', JSON.stringify(runningOperations));
		this.setState({ runningOperations: runningOperations }, 'UPDATE_RUNNING_OPERATIONS');
		
		this.get(`/api/protection/jobs/${jobId}`)
			.then((response) => {
				let task = response.data;
				if (!task || _.includes(['pending', 'running'], task.status.toLowerCase())) {
					setTimeout(this.checkRunningOperation.bind(this), 3000, jobId, key);
					return;
				}

				delete runningOperations[this.constructor.name][key];
				localStorage.setItem('runningOperations', JSON.stringify(runningOperations));
				this.setState({ runningOperations: runningOperations }, 'UPDATE_RUNNING_OPERATIONS');
			})
			.catch((error) => {
				if (error?.response?.status === 404) {
					delete runningOperations[this.constructor.name][key];
					localStorage.setItem('runningOperations', JSON.stringify(runningOperations));
					this.setState({ runningOperations: runningOperations }, 'UPDATE_RUNNING_OPERATIONS');
				}
			});
	}

	head(url, config) {
		return httpClient.head(url, config);
	}
	
	get(url, config) {
		return httpClient.get(url, config);
	}
	
	post(url, data, config, key) {
		return httpClient.post(url, data, config)
			.then((response) => {
				let jobId = (response?.data?.jobId ?? false);
				if (jobId && key) {
					this.checkRunningOperation(jobId, key);
				}

				return response;
			});
	}

	put(url, data, config, key) {
		return httpClient.put(url, data, config)
			.then((response) => {
				let jobId = (response?.data?.jobId ?? false);
				if (jobId && key) {
					this.checkRunningOperation(jobId, key);
				}

				return response;
			});
	}

	patch(url, data, config, key) {
		return httpClient.patch(url, data, config)
			.then((response) => {
				let jobId = (response?.data?.jobId ?? false);
				if (jobId && key) {
					this.checkRunningOperation(jobId, key);
				}

				return response;
			});
	}

	delete(url, config, key) {
		return httpClient.delete(url, config)
			.then((response) => {
				let jobId = (response?.data?.jobId ?? false);
				if (jobId && key) {
					this.checkRunningOperation(jobId, key);
				}

				return response;
			});
	}

}

export default Store;
