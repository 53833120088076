import X from '../x';
import template from './alert.html';

customElements.define('x-alert', class Alert extends X {
	#alert;
	#template = template;
	
	constructor() {
		super();
		
		this.#render();
	}
	
	#render() {
		this.style.display = 'block';
		let template = document.createElement('template');
		template.innerHTML = this.#template;
		this.shadowRoot.appendChild(template.content.cloneNode(true));
		
		this.#alert = this.shadowRoot.querySelector('.alert');
		let variant = 'primary';
		if (this.hasAttribute('variant')) {
			variant = this.getAttribute('variant');
		}
		this.#alert.classList.add(`alert-${variant}`);
	}
});
