import Store, { INIT_STATE } from './store';
import Node from './node';

const GET_CENTRAL_EXTERNAL_URLS = 'GET_CENTRAL_EXTERNAL_URLS';
const GET_CENTRAL_LICENSE = 'GET_CENTRAL_LICENSE';
const LINK_LICENSE = 'LINK_LICENSE';
const UNLINK_LICENSE = 'UNLINK_LICENSE';
const SET_MAINTENANCE = 'SET_MAINTENANCE';
const SET_SERENITY_ENABLED = 'SET_SERENITY_ENABLED';

class Central extends Store {
	constructor() {
		const initialState = {
			central: {}
		};
		super();
		this.setState(initialState, INIT_STATE);
	}

	// External URLs

	fetchExternalUrls() {
		return this.get('/api/central/external-urls')
			.then((response) => {
				let central = this.getStateProperty('central');
				_.assign(central, response.data);
				this.setState({ central: central }, GET_CENTRAL_EXTERNAL_URLS);
				return response.data;
			});
	}

	// Licensing
	
	fetchLicense() {
		const isNotActivated = (response) => {
			return response?.status === 404 && /(.*)not(.*)activated(.*)/ig.test(response?.data?.detail);
		};
		
		return Promise.allSettled([
			Node.fetchTime(),
			this.get('/api/central/services/licensing')
		])
			.then(([responseTime, responseLicensing]) => {
				if (responseTime.status === 'rejected' || responseLicensing.status === 'rejected') {
					throw new Error();
				}
				
				let dateTime = responseTime.value;
				let license = responseLicensing.value.data;
				license.isActivated = true;
				let central = this.getStateProperty('central');
				_.assign(central, license);
				if (!_.isEmpty(central.maintenance)) {
					central.maintenance.activeMaintenancePlan = central.maintenance.plan;
					central.maintenance.isExpired = moment(central.maintenance.expiresOn).isBefore(moment(dateTime.date));
					
					if (!_.isEmpty(central.maintenance.renewals) && central.maintenance.expiresOn !== central.maintenance.endDate) {
						let activeRenewal = _.find(central.maintenance.renewals, (renewal) => {
							return moment(renewal.endDate).isAfter(moment(dateTime.date));
						});
						
						if (activeRenewal) {
							central.maintenance.hasSerenity = activeRenewal.hasSerenity;
							central.maintenance.activeMaintenancePlan = activeRenewal.plan;
						}
					}
				}
				this.setState({ central: central }, GET_CENTRAL_LICENSE);
				return license;
			})
			.catch((error) => {
				if (isNotActivated(error?.response)) {
					let central = this.getStateProperty('central');
					_.assign(central, { isActivated: false });
					this.setState({ central: central }, GET_CENTRAL_LICENSE);
					return null;
				}
				
				throw error;
			});
	}

	fetchDetails() {
		return new Promise((resolve) => {
			Promise.allSettled([
				this.fetchLicense(),
				this.fetchExternalUrls()
			])
				.then(([responseLicense]) => {
					let central = this.getStateProperty('central');
					if (responseLicense.status === 'rejected') {
						if (!Object.prototype.hasOwnProperty.call(central, 'isActivated')) { // I dare you to change this and face the wrath of ESLint
							central.isActivated = false;
						}
					}
					resolve(central);
				});
		});
	}
	
	getDetails() {
		let central = this.getStateProperty('central');
		if (!_.isEmpty(central)) {
			return this.createPromise(central);
		}

		return this.fetchDetails();
	}

	refresh() {
		return this.post('/api/central/sync')
			.then(() => {
				return this.fetchLicense();
			});
	}

	link(data) {
		return this.post(
			'/api/central/services/licensing',
			data,
			{
				noIntercept: true
			}
		).then((response) => {
			let license = response.data;
			license.isActivated = true;
			let central = this.getStateProperty('central');
			_.assign(central, license);
			this.setState({ central: central }, LINK_LICENSE);
			return central;
		});
	}

	unlink() {
		return this.delete('/api/central/services/licensing')
			.then(() => {
				//TODO: call API for logout
				localStorage.removeItem('user');
				this.setState({ central: undefined }, UNLINK_LICENSE);
			});
	}

	// Maintenance

	setMaintenance(maintenance) {
		let central = this.getStateProperty('central');
		central.maintenance = maintenance;
		this.setState({ central: central }, SET_MAINTENANCE);
	}

	// Serenity

	toggleSerenity(enabled, auth) {
		return this.put(
			'/cake/central/serenity.json',
			null,
			{
				params: {
					event: enabled ? 'enable' : 'disable'
				},
				headers: { 'continuous-auth': auth }
			}
		)
			.then((response) => {
				let data = response.data;
				if (!data.succeeded) {
					throw new Error(data.message);
				}

				let central = this.getStateProperty('central');
				central.product.serenityEnabled = enabled;
				this.setState({ central: central }, SET_SERENITY_ENABLED);
				return central;
			});
	}

	// Health

	getHealth() {
		return this.get('/api/central/health')
			.then((response) => {
				return response.data.status.toLowerCase();
			});
	}

}

export default new Central();
