import 'construct-style-sheets-polyfill';
import style from '../../../dist/app/assets/css/theme.css';

let sheet = new CSSStyleSheet();
sheet.replace(style);

export default class X extends HTMLElement {
	static formAssociated = true;
	
	constructor() {
		super();
		
		this.attachShadow({
			mode: 'open',
			delegatesFocus: (this.type === 'input')
		});
		this.shadowRoot.adoptedStyleSheets = [sheet];
		
		this.#addToggles();
	}
	
	#addToggles() {
		if (this.hasAttribute('toggle')) {
			if (this.getAttribute('toggle') === 'modal' && this.hasAttribute('target')) {
				this.addEventListener('click' , (event) => {
					let modal = new bootstrap.Modal(document.querySelector(this.getAttribute('target')));
					modal.show(this);
				});
			}
		}
		if (this.hasAttribute('title')) {
			let tooltip = new bootstrap.Tooltip(this, {
				title: this.getAttribute('title'),
				placement: this.getAttribute('title-placement') || 'right'
			});
			this.removeAttribute('title');
			this.addEventListener('mouseenter' , (event) => {
				tooltip.show();
			});
			this.addEventListener('mouseleave' , (event) => {
				tooltip.hide();
			});
		}
	}
}
