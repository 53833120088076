import * as i18n from 'i18n/i18n';
let app = { t: i18n.t };

FormValidation.Framework.Bootstrap.prototype.disableSubmitButtons = function () {
	return this;
};

FormValidation.I18n = $.extend(true, FormValidation.I18n || {}, {
	'en_US': {
		notEmpty: {
			'default': app.t('validator:phrases.cannotBeEmpty')
		}
	}
});

FormValidation.Validator.networkAddress = {
	/**
	 * Return true if the input value is a network address.
	 * Validates an IP/netmask combination. Ex: 192.168.1.17/24 or 172.16.0.3/255.255.255.0
	 *
	 * @param {FormValidation.Base} validator The validator plugin instance
	 * @param {jQuery} $field Field element
	 * @param {Object} [options]
	 * @returns {Boolean|Object}
	 */
	validate: (validator, $field) => {
		let value = validator.getFieldValue($field, 'networkAddress');
		if (value === '') {
			return true;
		}

		let addressParts = _.split($field.val(), '/');
		if (addressParts.length !== 2) {
			return { valid: false, message: app.t('validator:phrases.invalidNetworkAddress') };
		}

		let ipv4Regex = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
		if (!ipv4Regex.test(_.first(addressParts))) {
			return { valid: false, message: app.t('validator:phrases.invalidNetworkAddress') };
		}

		let netmaskQuadRegex = /^(((0|128|192|224|240|248|252|254)\.0\.0\.0)|(255\.(0|128|192|224|240|248|252|254)\.0\.0)|(255\.255\.(0|128|192|224|240|248|252|254)\.0)|(255\.255\.255\.(0|128|192|224|240|248|252|254|255)))$/;
		let netmaskBitsRegex = /^([0-9]|[1-2][0-9]|3[0-2])$/;
		if (!(netmaskBitsRegex.test(addressParts[1]) || netmaskQuadRegex.test(addressParts[1]))) {
			return { valid: false, message: app.t('validator:phrases.invalidNetworkAddress') };
		}

		return true;
	}
};

FormValidation.Validator.hostname = {
	/**
	 * Return true if the input value is a hostname.
	 *
	 * @param {FormValidation.Base} validator The validator plugin instance
	 * @param {jQuery} $field Field element
	 * @param {Object} [options]
	 * @returns {Boolean|Object}
	 */
	validate: (validator, $field) => {
		let value = validator.getFieldValue($field, 'hostname');
		if (value === '') {
			return true;
		}

		let hostnameRegex = /(?=^.{1,63}$)(^(?:[a-z0-9]|[a-z0-9]\-?)*(?:[0-9a-z])$)/i;
		if (!hostnameRegex.test(value)) {
			return { valid: false, message: app.t('validator:phrases.invalidHostname') };
		}

		return true;
	}
};

FormValidation.Validator.domainName = {
	/**
	 * Return true if the input value is a domain name.
	 *
	 * @param {FormValidation.Base} validator The validator plugin instance
	 * @param {jQuery} $field Field element
	 * @param {Object} [options]
	 * @returns {Boolean|Object}
	 */
	validate: (validator, $field) => {
		let value = validator.getFieldValue($field, 'domainName');
		if (value === '') {
			return true;
		}

		let domainNameRegex = /^(?=.{1,191}$)((?=[a-z0-9-]{1,63}(\.?))[a-z0-9]+((-+)[a-z0-9]+)*(\.?))+((?!-)[a-z0-9-]{0,}[a-z0-9](\.?))$/i;
		if (!domainNameRegex.test(value)) {
			return { valid: false, message: app.t('validator:phrases.invalidDomainName') };
		}

		return true;
	}
};

FormValidation.Validator.fqdn = {
	/**
	 * Return true if the input value is a FQDN.
	 *
	 * @param {FormValidation.Base} validator The validator plugin instance
	 * @param {jQuery} $field Field element
	 * @param {Object} [options]
	 * @returns {Boolean|Object}
	 */
	validate: (validator, $field) => {
		let value = validator.getFieldValue($field, 'fqdn');
		if (value === '') {
			return true;
		}

		let fqdnRegex = /^(?=.{1,254}$)((?=[a-z0-9-]{1,63}\.)[a-z0-9]+((-+)[a-z0-9]+)*\.)+((?!-)[a-z0-9-]{0,}[a-z0-9](\.?))$/i;
		if (!fqdnRegex.test(value)) {
			return { valid: false, message: app.t('validator:phrases.invalidFQDN') };
		}

		return true;
	}
};

FormValidation.Validator.port = {
	/**
	 * Return true if the input value is a number between 1 and 65535.
	 *
	 * @param {FormValidation.Base} validator The validator plugin instance
	 * @param {jQuery} $field Field element
	 * @param {Object} [options]
	 * @returns {Boolean|Object}
	 */
	validate: (validator, $field) => {
		let value = validator.getFieldValue($field, 'port');
		if (value === '') {
			return true;
		}

		if (!(value > 0 && value <= 65535)) {
			return { valid: false, message: app.t('validator:phrases.mustBeBetweenValues', { min: 1, max: 65535 }) };
		}

		return true;
	}
};

FormValidation.Validator.scsiIdentifier = {
	/**
	 * Return true if the input value is a SCSI identifier.
	 *
	 * @param {FormValidation.Base} validator The validator plugin instance
	 * @param {jQuery} $field Field element
	 * @param {Object} [options]
	 * @returns {Boolean|Object}
	 */
	validate: function (validator, $field) {
		let value = validator.getFieldValue($field, 'scsiIdentifier');
		if (value === '') {
			return true;
		}

		let scsiIdentifierRegex = /^(?:(iqn)\.[0-9]{4}-[0-9]{2}(?:\.[A-Za-z](?:[A-Za-z0-9\-]*[A-Za-z0-9])?)+(?::.*)?|(wwn|eui)\.[0-9A-Fa-f]{16}$)/;
		if (!scsiIdentifierRegex.test(value)) {
			return { valid: false, message: app.t('validator:phrases.invalidScsiIdentifier') };
		}
		
		return true;
	}
};

FormValidation.Validator.poolName = {
	/**
	 * Return true if the input value is a pool name.
	 *
	 * @param {FormValidation.Base} validator The validator plugin instance
	 * @param {jQuery} $field Field element
	 * @param {Object} [options]
	 * @returns {Boolean|Object}
	 */
	validate: function (validator, $field) {
		let value = validator.getFieldValue($field, 'poolName');
		if (value === '') {
			return true;
		}
		
		if (/^[.]/i.test(value)) {
			return { valid: false, message: app.t('validator:phrases.cannotStartWithDot') };
		}
		
		if (!/^[\x20-\x7A]*$/i.test(value)) {
			return { valid: false, message: app.t('validator:phrases.cannotContainLettersWithAccents') };
		}
		
		if (!/^[a-zA-Z]/i.test(value)) {
			return { valid: false, message: app.t('validator:phrases.mustStartWithALetter') };
		}

		if (!/^[a-z0-9-_.]+$/i.test(value)) {
			return { valid: false, message: app.t('validator:phrases.canOnlyContainLettersNumbers') };
		}
		
		return true;
	}
};

FormValidation.Validator.datasetName = {
	/**
	 * Return true if the input value is a dataset name.
	 *
	 * @param {FormValidation.Base} validator The validator plugin instance
	 * @param {jQuery} $field Field element
	 * @param {Object} [options]
	 * @returns {Boolean|Object}
	 */
	validate: function (validator, $field) {
		let value = validator.getFieldValue($field, 'datasetName');
		if (value === '') {
			return true;
		}

		if (/^[.]/i.test(value)) {
			return { valid: false, message: app.t('validator:phrases.cannotStartWithDot') };
		}

		if (!/^[\x20-\x7A]*$/i.test(value)) {
			return { valid: false, message: app.t('validator:phrases.cannotContainLettersWithAccents') };
		}

		if (!/^[a-zA-Z0-9]/i.test(value)) {
			return { valid: false, message: app.t('validator:phrases.mustStartWithAlphanumeric') };
		}

		return true;
	}
};

FormValidation.Validator.virtualMachineName = {
	/**
	 * Return true if the input value is a virtual machine name.
	 *
	 * @param {FormValidation.Base} validator The validator plugin instance
	 * @param {jQuery} $field Field element
	 * @param {Object} [options]
	 * @returns {Boolean|Object}
	 */
	validate: function (validator, $field) {
		let value = validator.getFieldValue($field, 'virtualMachineName');
		if (value === '') {
			return true;
		}

		if (/^[.]/i.test(value)) {
			return { valid: false, message: app.t('validator:phrases.cannotStartWithDot') };
		}

		if (!/^[\x20-\x7A]*$/i.test(value)) {
			return { valid: false, message: app.t('validator:phrases.cannotContainLettersWithAccents') };
		}

		if (!/^[a-zA-Z0-9]/i.test(value)) {
			return { valid: false, message: app.t('validator:phrases.mustStartWithAlphanumeric') };
		}

		if (!/^[\(\)a-zA-Z0-9-_.: ]+$/i.test(value)) {
			return { valid: false, message: app.t('validator:phrases.canOnlyContain') };
		}

		return true;
	}
};

FormValidation.Validator.networkName = {
	/**
	 * Return true if the input value is a dataset name.
	 *
	 * @param {FormValidation.Base} validator The validator plugin instance
	 * @param {jQuery} $field Field element
	 * @param {Object} [options]
	 * @returns {Boolean|Object}
	 */
	validate: function (validator, $field) {
		let value = validator.getFieldValue($field, 'networkName');
		if (value === '') {
			return true;
		}

		if (/^[.]/i.test(value)) {
			return { valid: false, message: app.t('validator:phrases.cannotStartWithDot') };
		}

		if (!/^[\x20-\x7A]*$/i.test(value)) {
			return { valid: false, message: app.t('validator:phrases.cannotContainLettersWithAccents') };
		}

		if (!/^[a-z0-9-_. ]+$/i.test(value)) {
			return { valid: false, message: app.t('validator:phrases.canOnlyContainWithSpace') };
		}

		return true;
	}
};
