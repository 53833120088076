let list = {
	login: { index: {} },
	logout: { index: {} },
	dashboard: { index: {} },
	vmwareServers: { index: {} },
	hosts: { index: {} },
	sla: { index: {} },
	virtualMachines: {
		index: {},
		replicas: {},
		console: {}
	},
	replicationTargets: {
		index: {}
	},
	imageRepository: {
		index: {}
	},
	network: {
		adapters: {}
	},
	disks: { index: {} },
	pools: { index: {} },
	users: {
		index: {},
		account: {}
	},
	time: { index: {} },
	firmware: {
		index: {},
		update: {}
	},
	tasks: { index: {} },
	help: { index: {} },
	system: {
		about: {},
		security: {},
		restart: {},
		shutdown: {}
	},
	errors: {
		'404': {}
	}
};

const getList = () => {
	if (!app.isAuthenticated) {
		return _.pick(list, 'login');
	}
	
	if (!app.user.isSuper) {
		_.unset(list, 'users.index');
		_.unset(list, 'system.security');
	}
	
	if (product.isCloudGate) {
		_.unset(list, 'hosts');
		_.unset(list, 'vmwareServers');
		_.unset(list, 'virtualMachines.index');
		_.unset(list, 'imageRepository');
		_.unset(list, 'sla');
		_.unset(list, 'disks');
		_.unset(list, 'pools');
		_.unset(list, 'firmware.index');
		_.unset(list, 'system.about');
		_.unset(list, 'system.restart');
		_.unset(list, 'system.shutdown');
	}
	
	return list;
};

const load = () => {
	return new Promise((resolve) => {
		let modulesToLoad = 0;
		let loadedModules = 0;
		
		const checkResolvePromise = () => {
			if (loadedModules >= modulesToLoad) {
				resolve();
			}
		};
		
		_.each(getList(), (views, controller) => {
			modulesToLoad += _.size(_.keys(views));
			_.each(views, (module, view) => {
				import(`../${_.snakeCase(_.singularize(controller))}/${(view !== 'index' ? `${_.singularize(view)}/` : '')}controller`)
					.then((importedModule) => {
						loadedModules++;
						module.object = importedModule;
						checkResolvePromise();
					});
			});
		});
	});
};

export {
	getList,
	load
};
