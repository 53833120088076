import X from '../x';
import template from './input.html';
import obscurityTogglePartial from './obscurity_toggle.html';
import IMask from 'imask/esm/index';

customElements.define('x-input', class Input extends X {
	#input;
	#initialValue;
	#initialDisabled;
	#initialReadOnly;
	#inputMask = null;
	#template = template;
	
	static get observedAttributes() {
		return ['label', 'value', 'disabled', 'readonly', 'autocomplete', 'error'];
	}
	
	constructor() {
		super();
		
		this.#render();
		this.#attachEventListeners();
	}
	
	formResetCallback() {
		this.setAttribute('value', this.#initialValue);
		this.removeAttribute('disabled');
		if (this.#initialDisabled) {
			this.toggleAttribute('disabled');
		}
		this.removeAttribute('readonly');
		if (this.#initialReadOnly) {
			this.toggleAttribute('readonly');
		}
		this.removeAttribute('error');
	}
	
	attributeChangedCallback(name, oldValue, newValue) {
		if (name === 'label') {
			this.#setLabel(newValue);
		}
		if (name === 'value') {
			this.#input.value = newValue;
			if (this.#inputMask) {
				this.#inputMask.value = newValue;
				this.#inputMask.updateValue();
			}
		}
		if (name === 'disabled') {
			this.#input.disabled = (newValue !== null);
		}
		if (name === 'readonly') {
			this.#input.readOnly = (newValue !== null);
		}
		if (name === 'autocomplete') {
			this.#input.autocomplete = (newValue !== null);
		}
		if (name === 'error') {
			this.shadowRoot.querySelector('.invalid-feedback.component').innerHTML = newValue;
			if (newValue === '' || newValue === null) {
				this.shadowRoot.querySelector('.invalid-feedback.component').classList.add('d-none');
				this.shadowRoot.querySelector('.form-group').classList.remove('has-error');
			} else {
				this.shadowRoot.querySelector('.form-group').classList.add('has-error');
				this.shadowRoot.querySelector('.invalid-feedback.component').classList.remove('d-none');
			}
		}
	}
	
	get name() {
		return this.getAttribute('name');
	}
	
	get type() {
		return 'input';
	}
	
	get nodeName() {
		return 'INPUT';
	}
	
	get value() {
		if (this.#inputMask) {
			return this.#inputMask.unmaskedValue;
		}
		
		return this.#input.value;
	}
	
	set value(value) {
		this.setAttribute('value', value);
	}
	
	get disabled() {
		return this.hasAttribute('disabled');
	}
	
	set disabled(value) {
		this.removeAttribute('disabled');
		if (String(value) === 'true') {
			this.toggleAttribute('disabled');
		}
	}
	
	get readonly() {
		return this.hasAttribute('readonly');
	}
	
	set readonly(value) {
		this.removeAttribute('readonly');
		if (String(value) === 'true') {
			this.toggleAttribute('readonly');
		}
	}
	
	get error() {
		return this.getAttribute('error');
	}
	
	set error(value) {
		if (typeof value === 'string' || typeof value === 'number') {
			this.setAttribute('error', value);
		} else {
			this.removeAttribute('error');
		}
	}
	
	#setLabel(value) {
		let label = value;
		if (this.hasAttribute('help-text')) {
			label += `<span class="help-inline ms-1" data-bs-toggle="tooltip" title="${this.getAttribute('help-text')}"><i class="fas fa-question-circle text-body-secondary"></i></span>`;
		}
		this.shadowRoot.querySelector('label').innerHTML = label;
	}
	
	#render() {
		let template = document.createElement('template');
		template.innerHTML = this.#template;
		this.shadowRoot.appendChild(template.content.cloneNode(true));
		
		this.#input = this.shadowRoot.querySelector('input');
		this.#input.type = this.getAttribute('type') || 'text';
		this.#input.placeholder = this.getAttribute('placeholder') || ' ';
		this.#initialValue = this.getAttribute('value') || '';
		this.#initialDisabled = this.hasAttribute('disabled');
		this.#initialReadOnly = this.hasAttribute('readonly');
		
		if (this.hasAttribute('plainText')) {
			this.#input.classList.add('form-control-plaintext');
		}
		
		if (this.hasAttribute('prefix') && this.getAttribute('prefix') !== '') {
			this.shadowRoot.querySelector('.form-floating').insertAdjacentHTML('beforebegin', `<span class="input-group-text border-bottom align-items-end">${this.getAttribute('prefix')}</span>`);
		}
		
		if (this.hasAttribute('suffix') && this.getAttribute('suffix') !== '') {
			this.shadowRoot.querySelector('.form-floating').insertAdjacentHTML('afterend', `<span class="input-group-text border-bottom align-items-end">${this.getAttribute('suffix')}</span>`);
		}

		if (this.hasAttribute('gapless')) {
			this.shadowRoot.querySelector('.form-group').classList.add('mb-0');
		}
		
		if (this.hasAttribute('mask')) {
			let options = JSON.parse(this.getAttribute('mask').replace(/'/g, '"'));
			if (options.type === 'number') {
				let mask = {
					mask: `num ${options.suffix.replace('a', '\\a')}`,
					lazy: false,
					blocks: { num: { mask: new RegExp(`^\\d{0,${options.maxlength}}$`) } }
				};
				this.#inputMask = IMask(this.#input, mask);
			}
		}
		
		if (this.#input.type === 'password' && (!this.hasAttribute('has-toggle') || this.getAttribute('has-toggle') === 'true')) {
			this.shadowRoot.querySelector('.input-group').insertAdjacentHTML('beforeend', obscurityTogglePartial);
		}
	}
	
	#attachEventListeners() {
		this.#input.addEventListener('keypress', (event) => {
			if (event.code.toLowerCase() === 'enter') {
				let form = this.closest('form');
				if (form) {
					form.dispatchEvent(new event.constructor('submit', event));
				}
			}
		});
		
		this.#input.addEventListener('keyup', (event) => {
			this.dispatchEvent(new event.constructor('change', event));
		});
		
		if (this.getAttribute('type') === 'password' && (!this.hasAttribute('has-toggle') || this.getAttribute('has-toggle') === 'true')) {
			this.shadowRoot.querySelector('.obscurity-toggle').addEventListener('click', (event) => {
				let isValueVisible = (this.#input.type !== 'password');
				this.#input.type = (isValueVisible ? 'password' : 'text');
				this.shadowRoot.querySelector('.fa-eye').classList.toggle('d-none', !isValueVisible);
				this.shadowRoot.querySelector('.fa-eye-slash').classList.toggle('d-none', isValueVisible);
			});
		}
		
		this.shadowRoot.querySelectorAll('label').forEach((trigger) => {
			new bootstrap.Tooltip(trigger);
		});
	}
});
